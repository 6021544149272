import {
  QueryClient,
  QueryClientProvider,
  useIsFetching,
} from '@tanstack/react-query'
import 'dayjs/locale/en'
import 'dayjs/locale/ko'
import { lazy, useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, useLocation } from 'react-router-dom'

import { GlobalAlertPopup } from '@/components/common/alert-popup/global-alert-popup'
import { GlobalModal } from '@/components/common/modal/global-modal'
import { changeLanguage, getCurrentLanguage } from '@/lib/i18n'

import Spinner from './components/desktop/spinner/spinner'

import './styles/_index.scss'

const DefaultRoutes = lazy(() => import('./routes'))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
    },
  },
})

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation()

    useEffect(() => {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 200)
    }, [pathname])

    return null
  }

  useEffect(() => {
    changeLanguage(getCurrentLanguage())
  }, [])

  const LoadingSpinner = () => {
    const isFetching = useIsFetching()

    return isFetching ? <Spinner /> : null
  }

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <LoadingSpinner />
          <DefaultRoutes />
          <GlobalModal />
          <GlobalAlertPopup />
          <ScrollToTop />
        </BrowserRouter>
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default App
