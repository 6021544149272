import { DeviceType } from '@/types/global-enum'

/**
 * 로그인 링크를 업데이트하는 함수
 */
const updateLoginLinks = (loginUrl: string, device: DeviceType): boolean => {
  const className = device === DeviceType.PC ? 'gnbUtil' : 'btnAccount'
  const elements = document.getElementsByClassName(className)

  for (let i = 0; i < elements.length; i++) {
    const links = elements[i].getElementsByTagName('a')
    for (let j = 0; j < links.length; j++) {
      const link = links[j]
      if (
        link.href.toLowerCase().includes('loginform.do') &&
        !link.href.includes(loginUrl)
      ) {
        link.href += loginUrl
        return true
      }
    }
  }

  return false
}

/**
 * 블루웨이브용 해더에서 로그인버튼에 콜백 URL을 추가하는 함수
 */
export const loginCallbackUrlParser = (
  url: string,
  device: DeviceType
): void => {
  const landingUrl = encodeURIComponent(
    `${process.env.REACT_APP_PROXY_URL}?path=${url}&url=${window.location.origin}`
  )
  const loginUrl = `?returnUrl=${landingUrl}`

  updateLoginLinks(loginUrl, device)
}

/**
 * 블루웨이브용 로그인 URL을 가져오는 함수
 */
export const getLoginUrl = (url: string, device: DeviceType): string => {
  const className = device === DeviceType.PC ? 'gnbUtil' : 'btnAccount'
  const elements = document.getElementsByClassName(className)

  for (let i = 0; i < elements.length; i++) {
    const links = elements[i].getElementsByTagName('a')
    for (let j = 0; j < links.length; j++) {
      if (links[j].href.toLowerCase().includes('loginform.do')) {
        return links[j].href
      }
    }
  }

  return '/step0'
}

/**
 *  URL에서 쿼리스트링을 가져오는 함수
 * @param key
 * @returns  string | null
 */
export const getQueryParam = (key: string): string | null => {
  const params = new URLSearchParams(window.location.search)
  return params.get(key)
}

/**
 * URL에서 쿼리스트링을 가져오는 함수
 */
export const parseHtmlString = (htmlString: string, selector: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')
  return Array.from(doc.querySelectorAll(selector))
}

/**
 * HTML 문자열에서 style 속성을 제거하는 함수
 */
export const removeStyleAttributes = (htmlString: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')
  const elementsWithStyle = doc.querySelectorAll('[style]')
  elementsWithStyle.forEach((element) => {
    element.removeAttribute('style')
  })
  return doc.body.innerHTML
}

/**
 * URL 쿼리 파라미터에서 연속된 파라미터들을 배열 형태로 가져오는 함수
 * @param keyPrefix: string
 */

export const getArrayQueryParam = (keyPrefix: string): number[] => {
  const params = new URLSearchParams(window.location.search)

  if (!params.toString()) {
    return []
  }

  const values: number[] = []
  let i = 1

  let value = params.get(`${keyPrefix}${i}`)
  while (value !== null) {
    values.push(Number(value))
    i++
    value = params.get(`${keyPrefix}${i}`)
  }

  return values
}
