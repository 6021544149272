export enum Locale {
  'kr' = 'ko',
  'en' = 'en',
  'cn' = 'cn',
  'jp' = 'ja',
}

export enum Currency {
  KRW = 'KRW',
  USD = 'USD',
  CNY = 'CNY',
  JPY = 'JPY',
  EUR = 'EUR',
}

export enum DeviceType {
  PC = 'PC',
  MO = 'MO',
  APP = 'APP',
}

export enum TabType {
  ONLY_MEMBERS = 'onlyMembers',
  PRODUCTS = 'products',
  ROOMS = 'rooms',
}

export enum CodeGroup {
  RATE = 'RATE',
  ROOM_TYPE = 'ROOM_TYPE',
  MAIN_CATEGORY_CODE = 'MAIN_CATEGORY_CODE',
  BED_CODE = 'BED_CODE',
}

export enum CommonCodeGroup {
  DICTIONARY = 'DICTIONARY',
  BE_PRODUCT_KEYWORD = 'BE_PRODUCT_KEYWORD',
  LANGUAGE = 'LANGUAGE',
  COUNTRY = 'COUNTRY',
  COUNTRY_PHONE = 'COUNTRY_PHONE',
  CARD_COMPANY = 'CARD_COMPANY',
}
export enum CommonCodeGroupDictionary {
  REFUND_POLICY = 'REFUND_POLICY',
  REFUND_POLICY_VCH = 'REFUND_POLICY_VCH',
}

export enum CouponType {
  AMOUNT = '10',
  DISCOUNT = '11',
  EXCHANGE = '12',
}

export enum ImageType {
  DESKTOP_BE = 'DESKTOP_BE',
  MOBILE_BE = 'MOBILE_BE',
  DESKTOP_MAIN = 'DESKTOP_MAIN',
  DESKTOP_THUMBNAIL = 'DESKTOP_THUMBNAIL',
  MOBILE_MAIN = 'MOBILE_MAIN',
  MOBILE_THUMBNAIL = 'MOBILE_THUMBNAIL',
  BENEFIT = 'BENEFIT',
  DESKTOP_IMAGE = 'DESKTOP_IMAGE',
  MOBILE_IMAGE = 'MOBILE_IMAGE',
}

export enum BedDescriptionType {
  ICON_KING_A = 'ICON_KING_A',
  ICON_ONDOL_A = 'ICON_ONDOL_A',
  ICON_TWIN_A = 'ICON_TWIN_A',
}

export enum Gender {
  MALE = '남성',
  FEMALE = '여성',
}

export enum Property {
  BUSAN = 4,
  JEJU = 5,
}

export enum OperatingHours {
  BUSAN_CHECK_IN = 15,
  BUSAN_CHECK_OUT = 11,
  JEJU_CHECK_IN = 15,
  JEJU_CHECK_OUT = 12,
}

export enum PrepaymentType {
  RESERVATION_ONLY = 'RESERVATION_ONLY',
  RESERVATION_PREPAYMENT = 'RESERVATION_PREPAYMENT',
  PREPAYMENT_ONLY = 'PREPAYMENT_ONLY',
}

export enum PromotionType {
  PROMOTION = 'PROMOTION',
  COMPANY = 'COMPANY',
}

export enum PaymentType {
  PG = 'PG',
  POINT = 'POINT',
  COUPON = 'COUPON',
  ON_SITE = 'ON_SITE',
  VOUCHER = 'VOUCHER',
}

export enum PaymentProviderType {
  NICEPAY = 'NICEPAY',
  SSG_POINT = 'SSG_POINT',
  SSG_COUPON = 'SSG_COUPON',
  SSG_VOUCHER = 'SSG_VOUCHER',
  ON_SITE = 'ON_SITE',
}

export enum ReservationProgressType {
  RESERVATION_ONLY = 'RESERVATION_ONLY',
  PREPAYMENT_ONLY = 'PREPAYMENT_ONLY',
}

export enum DeviceHeaderHeights {
  PC = 100,
  MOBILE = 56,
}

export enum MemberGrade {
  GOLD = 'Gold',
  PREMIER = 'Premier',
  PLATINUM = 'Platinum',
  BLACK = 'Black',
}

export enum CardType {
  INDIVIDUAL = '1',
  CORPORATE_NAMED = '2',
  CORPORATE_UNNAMED = '3',
}
